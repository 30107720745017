<template>
    <b-container fluid>
        <div class="mb-2 row">
            <div class="col-12">
                <h4>{{ dealer.name}}</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline card-outline-tabs">
                    <div class="p-0 card-header border-bottom-0">
                        <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                            <li class="nav-item">
                                <a href="#custom-tabs-four-general" class="nav-link active" id="custom-tabs-four-general-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-general" aria-selected="true">Allgemein</a>
                            </li>
                            <li class="nav-item">
                                <a href="#custom-tabs-four-articles" class="nav-link" id="custom-tabs-four-articles-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-articles" aria-selected="true">Artikel</a>
                            </li>
                            <li class="nav-item">
                                <a href="#custom-tabs-four-contacts" class="nav-link" id="custom-tabs-four-contacts-tab" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-contacts" aria-selected="false">Kontakte</a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div class="tab-content" id="custom-tabs-four-tabContent">
                            <div class="tab-pane fade active show" id="custom-tabs-four-general" role="tabpanel" aria-labelledby="custom-tabs-four-general">
                                <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">
                                    <div class="row">
                                        <div class="col-md-6">

                                            <div class="form-group" v-if="dealer.thumbnail != ''">
                                                <div class="row align-items-center">
                                                    <div class="col-md-5">
                                                        <label for="name" class="control-label">Name*</label>
                                                        <input v-model="form.name" name="name" id="name" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('name')}" disabled>
                                                        <has-error :form="form" field="name"></has-error>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <label for="number" class="control-label">Händlernummer*</label>
                                                        <input v-model="form.number" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('number')}" disabled>
                                                        <has-error :form="form" field="number"></has-error>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <label for="discount" class="control-label">Rabatt(%)*</label>
                                                        <input v-model="form.discount" name="discount" id="discount" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('discount')}" disabled>
                                                        <has-error :form="form" field="discount"></has-error>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <figure class="float-right mt-2" style="width: 70%; height: auto;">
                                                            <img v-bind:src="dealer.thumbnail" id="brandLogo" class="img-fluid" alt="img">
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group" v-if="dealer.thumbnail == ''">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <label for="name" class="control-label">Name*</label>
                                                        <input v-model="form.name" name="name" id="name" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('name')}" disabled>
                                                        <has-error :form="form" field="name"></has-error>
                                                    </div>

                                                    <div class="col-md-4">
                                                        <label for="number" class="control-label">Händlernummer*</label>
                                                        <input v-model="form.number" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('number')}" disabled>
                                                        <has-error :form="form" field="number"></has-error>
                                                    </div>

                                                    <div class="col-md-4">
                                                        <label for="discount" class="control-label">Rabatt(%)*</label>
                                                        <input v-model="form.discount" name="discount" id="discount" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('discount')}" disabled>
                                                        <has-error :form="form" field="discount"></has-error>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label for="active" class="control-label">Online anzeigen?*</label>
                                                <select v-model="form.active" type="text" class="form-control" :class="{'is-invalid': form.errors.has('active')}" disabled>
                                                    <option value="1">Ja</option>
                                                    <option value="0">Nein</option>
                                                </select>
                                                <has-error :form="form" field="active"></has-error>
                                            </div>

                                            <div class="form-group">
                                                <label for="name" class="control-label">Beschreibung</label>
                                                <ckeditor :editor="editor" :config="editorConfig" v-model="form.description" :class="{'is-invalid': form.errors.has('description')}" disabled></ckeditor>
                                                <has-error :form="form" field="description"></has-error>
                                            </div>

                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-10">
                                                        <div class="form-group">
                                                            <label for="street" class="control-label">Straße*</label>
                                                            <input v-model="form.street" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('street')}" disabled>
                                                            <has-error :form="form" field="street"></has-error>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <label for="house_number" class="control-label">Nr.*</label>
                                                            <input v-model="form.house_number" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('house_number')}" disabled>
                                                            <has-error :form="form" field="house_number"></has-error>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <label for="zipcode" class="control-label">PLZ*</label>
                                                        <input v-model="form.zipcode" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('zipcode')}" disabled>
                                                        <has-error :form="form" field="zipcode"></has-error>
                                                    </div>

                                                    <div class="col-md-8">
                                                        <label for="city" class="control-label">Ort*</label>
                                                        <input v-model="form.city" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('city')}" disabled>
                                                        <has-error :form="form" field="city"></has-error>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label for="logo" class="control-label">Logo</label>
                                                <b-form-file id="logo" v-model="form.logo" :class="{'is-invalid': form.errors.has('logo')}" disabled/>
                                                <has-error :form="form" field="logo"></has-error>
                                            </div>

                                            <div class="form-group">
                                                <label for="notice" class="control-label">Notizen</label>
                                                <ckeditor :editor="editor" :config="editorConfig" v-model="form.notice" :class="{'is-invalid': form.errors.has('notice')}" disabled></ckeditor>
                                                <has-error :form="form" field="notice"></has-error>
                                            </div>

                                        </div>
                                        <div class="col-md-6">
                                            <GmapMap
                                            :center="{lat:latitude, lng:longitude}"
                                            :zoom="15"
                                            map-type-id="terrain"
                                            style="width: 100%; height: 100%"
                                            >
                                                <GmapMarker
                                                    :position="google && new google.maps.LatLng(dealer.longitude, dealer.latitude)"
                                                />
                                            </GmapMap>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="tab-pane fade" id="custom-tabs-four-articles" role="tabpanel" aria-labelledby="custom-tabs-four-articles">
                                <div class="row">
                                    <div class="col-md-6">
                                        <h3>Zugewiesene Artikel</h3>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <span v-if="articles.length <= 0">Diesem Händler sind noch keine Artikel zugewiesen</span>
                                        <table v-else class="table table-striped">
                                            <thead>
                                                    <th><input type="checkbox" v-model="selectAll" /></th>
                                                    <th>Artikelnummer</th>
                                                    <th>Artikelname</th>
                                                    <th>Hersteller</th>
                                                    <th>Preis</th>
                                                    <th>Steuersatz</th>
                                            </thead>

                                            <tbody>
                                                <tr v-for="(article,index) in articles" :key="article.id">
                                                    <td><input type="checkbox" v-model="selectedArticles" :value="article.id" number /></td>
                                                    <td>{{ article.ordernumber }}</td>
                                                    <td>{{ generateName(index) }}</td>
                                                    <td>{{ article.article.producer.name }}</td>
                                                    <td>{{ article.price | toCurrency}}</td>
                                                    <td>{{ article.tax }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="custom-tabs-four-contacts" role="tabpanel" aria-labelledby="custom-tabs-four-contacts">
                                <b-row class="mb-3" >
                                        <b-col md="3">
                                            <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Type to Search"></b-form-input>
                                        </b-col>
                                    </b-row>
                                    <b-row v-if="contacts.length >= 1">
                                        <b-col>
                                        <b-table striped hover outlined :fields="contactFields" :items="contacts" :filter="filter" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                                        </b-table>
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>
                                    </b-col>
                                    </b-row>
                                
                                <b-row v-if="contacts.length == 0">
                                    <b-col>
                                        <h4>Dieser Händler hat keine Kontakte</h4>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="contactModal" v-bind:title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event, contactForm.id)">
                <form ref="contactForm" @submit.stop.prevent="handleSubmit">
                    <div class="row">
                        <div class="col-md-12">

                            <div class="form-group">
                                <label for="name" class="control-label">Name*</label>
                                <input v-model="contactForm.name" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': contactForm.errors.has('name')}">
                                <has-error :form="contactForm" field="name"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="department" class="control-label">Abteilung*</label>
                                <input v-model="contactForm.department" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': contactForm.errors.has('department')}">
                                <has-error :form="contactForm" field="department"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="email" class="control-label">E-Mail*</label>
                                <input v-model="contactForm.email" type="email" class="form-control form-cotrol-sm" :class="{'is-invalid': contactForm.errors.has('email')}">
                                <has-error :form="contactForm" field="email"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="phone" class="control-label">Telefon*</label>
                                <input v-model="contactForm.phone" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': contactForm.errors.has('phone')}">
                                <has-error :form="contactForm" field="phone"></has-error>
                            </div>

                            
                        </div>
                    </div>
                </form>
            </b-modal>

            <ArticleModal />
    </b-container>
</template>

<script>
import { gmapApi } from 'gmap-vue';
import ClassicEditor from './../../assets/packages/ckeditor5/src/ckeditor';

import ArticleModal from './components/AddModal';

export default {
    name: "Dealer-Details",
    title: "Händlerdetails",

    components: {
        ArticleModal,
    },

    data() {
        return {
            form: new window.UploadForm({
                id: "",
                name: "",
                number: "",
                description: "",
                street: "",
                house_number: "",
                zipcode: "",
                city: "",
                logo: [],
                notice: "",
                discount: "",
                active: "",
                _method: "PUT",
            }),
            contactForm: new window.Form({
                id: "",
                name: "",
                department: '',
                phone: '',
                email: '',
            }),
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            modalTitle: "Neuen Kontakt erstellen",
            contacts: [],
            dealer: [],
            editMode: false,
            editModeContact: false,
            latitude: 0,
            longitude: 0,
            sortBy: "id",
            sortDesc: false,
            perPage: 20,
            currentPage: 1,
            filter: "",
            contactFields: [
                {key: "id", sortable: true},
                {key: "name", label: "Name", sortable: true},
                {key: "department", label: "Abteilung", sortable: true},
                {key: "email", label: "E-Mail", sortable: true },
                {key: "phone", label: "Telefon", sortable: true},
                {key: "actions", label: "Actions", tdClass: 'hls_action_column'},
            ],
            selectedArticles: [],
            articles: [],
        }
    }, 

    methods:{

        exportArticles(){
            this.$Progress.start();
            this.axios
                .get("/dealers/" + this.$route.params.id + '/articles/export/csv',{
                    responseType: 'blob',
                })
                .then((response) => {
                    this.$Progress.finish();
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
                    );

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", 'Stammdaten.xlsx');
                    document.body.appendChild(link);

                    // Dynamicall click the a link element
                    // This will download the csv file
                    link.click();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        deleteArticle(article){
            this.$swal({
                title: "Möchtest du den Artikel wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    var articles = [article];
                    this.axios
                        .delete("/dealers/" + this.dealer.id + '/articles', {
                            data: {
                                'selectedArticles': articles
                            }
                        })
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Artikel wurde gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.loadDealer();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },

        deleteArticles(){
            this.$swal({
                title: "Möchtest du die selektierten Artikel wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.axios
                        .delete("/dealers/" + this.dealer.id + '/articles', {
                            data: {
                                'selectedArticles': this.selectedArticles
                            }
                        })
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Artikel wurde gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.selectedArticles = [];
                            this.loadDealer();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },

        generateName(index)
        {
            var article = this.dealer.articles[index];
            var name = article.article.name;

            article.values.forEach(function (value) {
                name = name + ' - ' + value.name;
            });

            return name;
            
        },

        showArticleModal(){
            this.$bvModal.show("articleModal");
        },
        

        handleOk(bvModalEvt, id) {
            bvModalEvt.preventDefault()
            this.handleSubmit(id)
        },

        handleSubmit(id) {
            if(this.editModeContact == true)
            {
                //Edit User
                this.editContact(id);
            }
            else
            {
                //Create User
                this.createContact();
            }
        },

        createModal() {
            this.contactForm.clear();
            this.contactForm.reset();
            this.editModeContact = false;
            this.$bvModal.show("contactModal");
        },

        editModal(Contact) {
            this.editModeContact = true;
            this.modalTitle = "Kontakt bearbeiten";
            this.contactForm.reset();
            this.contactForm.fill(Contact);
            this.$bvModal.show("contactModal");

        },

        editModeOn() {
            this.editMode = true;
        },

        editModeOff() {
            this.form.reset();
            this.form.fill(this.dealer);
            this.form._method ="PUT";
            this.editMode = false;
        },

        createContact() {
            this.$Progress.start();
            this.contactForm
                .post("/dealers/" + this.dealer.id + '/contacts')
                .then(() => {
                    this.loadDealer();
                    this.$bvModal.hide("contactModal");
                    this.$swal({
                        icon: "success",
                        title: "Kontakt wurde hinzugefügt",
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });
        },

        editContact(id) {
            this.$Progress.start();
            this.contactForm
                .put("/dealers/" + this.dealer.id + '/contacts/' + id)
                .then(() => {
                    this.loadDealer();
                    this.$bvModal.hide("contactModal");
                    this.$swal({
                        icon: "success",
                        title: "Der Kontakt wurde editiert",
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });
        },

        deleteContact(id){
            this.$swal({
                title: "Möchtest du den Kontakt wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.form
                        .delete("/dealers/" + this.dealer.id + '/contacts/' + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Kontakt wurde gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.loadDealer();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },

        editDealer() {
            this.$Progress.start();
            this.form
                .post("/dealers/"+this.dealer.id)
                .then(() => {
                    this.loadDealer();
                    this.editModeOff();
                    this.$swal({
                        icon: "success",
                        title: "Händler wurde editiert",
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });
        },

        loadDealer() {
            this.$Progress.start();
            this.axios
                .get("/dealers/" + this.$route.params.id)
                .then((response) => {
                    this.dealer = response.data.data;
                    this.contacts = this.dealer.contacts;
                    this.articles = this.dealer.articles;
                    if(this.dealer.notice == null)
                    {
                        this.dealer.notice = "";
                    }
                    if(this.dealer.description == null)
                    {
                        this.dealer.description = "";
                    }
                    this.form.fill(this.dealer);
                    this.form._method = "PUT";
                    this.latitude = parseFloat(this.dealer.longitude);
                    this.longitude = parseFloat(this.dealer.latitude);
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },

    created() {
        this.loadDealer();
    },

    computed: {

        google: gmapApi,

        rows() {
            return this.contacts.length;
        },

        selectAll: {
            get: function () {
                return this.articles ? this.selectedArticles.length == this.articles.length : false;
            },
            set: function (value) {
                var selected = [];

                if(value) {
                    this.articles.forEach(function (article) {
                        selected.push(article.id);
                    });
                }

                this.selectedArticles = selected;
            }
        }

    },
}
</script>

<style>

</style>